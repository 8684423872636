import {ObservableCustomStore} from "../../../app/store/CustomStore";
import {AgroAhoMapStore, IAhoLayers} from "./agroAhoMapStore";
import {MBUtils} from "../../../app/helper/utils/MBUtils";
import {SymbolLayout, SymbolPaint} from "maplibre-gl";


export class AgroAhoMapSampleStore extends ObservableCustomStore {
    constructor(parent: AgroAhoMapStore) {
        super(parent);
        this.mapStore = parent;
    }

    mapStore: AgroAhoMapStore;
    icon_svg = `<svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 18.5C10.428 18.5 11.7439 18.2694 12.7212 17.8785C13.2085 17.6835 13.6364 17.4389 13.9506 17.1418C14.2659 16.8436 14.5 16.4579 14.5 16C14.5 15.5421 14.2659 15.1564 13.9506 14.8582C13.6364 14.5611 13.2085 14.3165 12.7212 14.1215C11.7439 13.7306 10.428 13.5 9 13.5C7.57196 13.5 6.25609 13.7306 5.27877 14.1215C4.7915 14.3165 4.36361 14.5611 4.04937 14.8582C3.73407 15.1564 3.5 15.5421 3.5 16C3.5 16.4579 3.73407 16.8436 4.04937 17.1418C4.36361 17.4389 4.7915 17.6835 5.27877 17.8785C6.25609 18.2694 7.57196 18.5 9 18.5Z" fill="#212121" stroke="white"/>
        <path d="M15.1251 6.24375C15.1251 3.10161 12.3317 0.625 9.00009 0.625C5.66844 0.625 2.87509 3.10161 2.87509 6.24375C2.87509 8.96453 4.97614 11.1833 7.69652 11.7278V14.9464C7.69652 15.6664 8.28015 16.25 9.00009 16.25C9.72003 16.25 10.3037 15.6664 10.3037 14.9464V11.7278C13.024 11.1833 15.1251 8.96453 15.1251 6.24375Z" fill="#212121" stroke="white"/>
        <circle cx="9" cy="6" r="2" fill="white"/>
        </svg>`;

    getOpt(){
        let cs = this.mapStore.parentStore.cardStore;
        return {ds: cs.card?.mix_sample_point_info?.ds_code, layer_id: IAhoLayers.SamplePoint};
    }

    async updSampleLayer(){
        await this.deleteSampleLayer();
        this.addSampleLayer();
    }

    async setSLHidden(hidden: boolean) {
        let map = this.root.map.mapbox;
        let opt = this.getOpt();
        if (!opt.ds) return;
        let layer_id =  this.mapStore.agroAhoTool.getAhoLayerId(opt.layer_id);
        if (!map.getLayer(layer_id)) return;
        let vis = hidden ? 'none' : 'visible';
        map.setLayoutProperty(layer_id, 'visibility', vis);
    }

    async addSampleLayer(){
        let opt = this.getOpt();
        if (!opt.ds) return;
        let map = this.root.map.mapbox;
        let source_id = this.mapStore.getCardSourceId(opt.ds);
        let layer_id =  this.mapStore.agroAhoTool.getAhoLayerId(opt.layer_id);

        if (!map.getSource(source_id)) {
            map.addSource(source_id, {
                type: 'vector',
                tiles: [`${window.location.origin}/api/vectorlayer/${opt.ds}/tile?z={z}&x={x}&y={y}&t=${Date.now()}`],
                scheme: "xyz"
            });
        }

        if (!map.getLayer(layer_id)) {
            let imgName = 'agroAho_sample_icon';
            if (!map.hasImage(imgName)) {
                await MBUtils.loadSvgToMapbox(this.icon_svg, map, 18, 18, imgName);
            }
            // console.log('add sample icon');

            map.addLayer({
                'id': layer_id,
                'type': 'symbol',
                'source': source_id,
                "source-layer": opt.ds,
                'minzoom': 15.00001,
                'layout': <SymbolLayout>{
                    'icon-image': imgName,
                    'icon-anchor': 'bottom',
                    "icon-allow-overlap": true,
                },
                'paint':  <SymbolPaint>{
                },
            }, await this.mapStore.getPrevLayer(opt.layer_id));
        }

    }

    deleteSampleLayer(){
        let opt = this.getOpt();
        if (!opt.ds) return;
        let map = this.root.map.mapbox;
        let source_id = this.mapStore.getCardSourceId(opt.ds);
        let layer_id =  this.mapStore.agroAhoTool.getAhoLayerId(opt.layer_id);
        if (map.getLayer(layer_id))
            map.removeLayer(layer_id);
        if (map.getSource(source_id))
            map.removeSource(source_id);
    }

}
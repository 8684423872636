// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AgroAhoPanelComp-main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-left: 1px solid #22282F;
  border-top: 1px solid #22282F;
  padding-bottom: 15px;
  position: relative;
}

.left-sidebar__right-top .top-button:nth-child(2),
.left-sidebar__right-top .top-button:nth-child(3) .arrow_pref {
  display: none;
}

.left-sidebar__right-top div.top-button.top-button-big {
  width: 40px;
}

.AgroAhoPanelComp-animateDouble {
  animation-iteration-count: 2;
  -webkit-animation-iteration-count: 2;
}

#map.AgroAhoTmpHidePopupMenuItem div.popup-right-click__row:nth-child(4),
#map.AgroAhoTmpHidePopupMenuItem div.legendLayer-panel {
  display: none;
}

.AgroAhoCursorPointer {
  cursor: pointer !important;
}

.AgroAhoWelcome-agroSoft,
.AgroAhoWelcome-c2g {
  text-decoration: none;
  color: #6B707B;
}

.AgroAhoWelcome-agroSoft {
  margin-right: 16px;
}`, "",{"version":3,"sources":["webpack://./plugins/agroAho/AgroAhoPanel/AgroAhoPanelComp.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,8BAAA;EACA,6BAAA;EACA,oBAAA;EACA,kBAAA;AACF;;AACA;;EAEE,aAAA;AAEF;;AAAA;EACE,WAAA;AAGF;;AADA;EACE,4BAAA;EACA,oCAAA;AAIF;;AAOA;;EAEE,aAAA;AAJF;;AAOA;EACE,0BAAA;AAJF;;AAQA;;EAEE,qBAAA;EACA,cAAA;AALF;;AAOA;EACE,kBAAA;AAJF","sourcesContent":[".AgroAhoPanelComp-main {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  border-left: 1px solid #22282F;\n  border-top: 1px solid #22282F;\n  padding-bottom: 15px;\n  position: relative;\n}\n.left-sidebar__right-top .top-button:nth-child(2),\n.left-sidebar__right-top .top-button:nth-child(3) .arrow_pref {\n  display: none;\n}\n.left-sidebar__right-top div.top-button.top-button-big {\n  width: 40px;\n}\n.AgroAhoPanelComp-animateDouble {\n  animation-iteration-count: 2;\n  -webkit-animation-iteration-count: 2;\n\n  //-webkit-animation-duration: calc(1s * 1.5);\n  //animation-duration: calc(1s * 1.5);\n  //-webkit-animation-duration: calc(var(--animate-duration) * 1.5);\n  //animation-duration: calc(var(--animate-duration) * 1.5);\n  //-webkit-animation-name: bounceInDouble;\n  //animation-name: bounceInDouble;\n  //-webkit-animation-fill-mode: both;\n  //animation-fill-mode: both;\n}\n#map.AgroAhoTmpHidePopupMenuItem div.popup-right-click__row:nth-child(4),\n#map.AgroAhoTmpHidePopupMenuItem div.legendLayer-panel {\n  display: none;\n}\n\n.AgroAhoCursorPointer {\n  cursor: pointer !important;\n}\n\n// >>> modify base code >>> //\n.AgroAhoWelcome-agroSoft,\n.AgroAhoWelcome-c2g {\n  text-decoration: none;\n  color: #6B707B;\n}\n.AgroAhoWelcome-agroSoft {\n  margin-right: 16px;\n}\n// <<< modify base code <<< //"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

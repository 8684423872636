import * as React from 'react';
import autoBindReact from "auto-bind/react";
import classNames from "classnames";


export interface IAgroAhoSampleSvgProps {
    className?: string;
    onClick?:(e: any)=>void;
}

export class AgroAhoSampleSvg extends React.PureComponent<IAgroAhoSampleSvgProps, undefined> {
    constructor(props: IAgroAhoSampleSvgProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        return <svg className={classNames(this.props.className)} onClick={this.props.onClick ? this.props.onClick : ()=>false}
            width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 18.5C10.428 18.5 11.7439 18.2694 12.7212 17.8785C13.2085 17.6835 13.6364 17.4389 13.9506 17.1418C14.2659 16.8436 14.5 16.4579 14.5 16C14.5 15.5421 14.2659 15.1564 13.9506 14.8582C13.6364 14.5611 13.2085 14.3165 12.7212 14.1215C11.7439 13.7306 10.428 13.5 9 13.5C7.57196 13.5 6.25609 13.7306 5.27877 14.1215C4.7915 14.3165 4.36361 14.5611 4.04937 14.8582C3.73407 15.1564 3.5 15.5421 3.5 16C3.5 16.4579 3.73407 16.8436 4.04937 17.1418C4.36361 17.4389 4.7915 17.6835 5.27877 17.8785C6.25609 18.2694 7.57196 18.5 9 18.5Z"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M9 1.125C12.101 1.125 14.625 3.42112 14.625 6.24375C14.625 8.81702 12.5245 10.9471 9.80357 11.304V14.9464C9.80357 15.3902 9.4438 15.75 9 15.75C8.5562 15.75 8.19643 15.3902 8.19643 14.9464V11.304C5.47554 10.9471 3.375 8.81702 3.375 6.24375C3.375 3.42112 5.89902 1.125 9 1.125ZM8.9998 7.99998C10.1044 7.99998 10.9998 7.10455 10.9998 5.99998C10.9998 4.89542 10.1044 3.99998 8.9998 3.99998C7.89523 3.99998 6.9998 4.89542 6.9998 5.99998C6.9998 7.10455 7.89523 7.99998 8.9998 7.99998Z"/>
        </svg>;
    }
}

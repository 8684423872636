import {CustomStoreTool} from "../../../../app/store/tools/general/CustomStoreTool";
import {CirclePaint, SymbolLayout, SymbolPaint} from "maplibre-gl";
import {IReactionDisposer} from "mobx/lib/internal";
import {autorun} from "mobx";
import {IAgroExportSuperStore} from "../../agroExportPlugin";
import {MBUtils} from "../../../../app/helper/utils/MBUtils";


export class SampleSelectionTool extends CustomStoreTool{
    static readonly LINES_NAME = "class_SampleSelection_LINES";
    static readonly CELLS_NAME = "class_SampleSelection_CELLS";
    static readonly CELLSTracks_NAME = "class_SampleSelection_CELLSTracks";
    static readonly CELLS_POINT_NAME = "class_SampleSelection_CELLS_POINT";
    static readonly CELLS_LABEL_NAME = "class_SampleSelection_CELLS_LABEL";
    static readonly SAMPLE_POINT_NAME = "class_SampleSelection_SAMPLE_POINT";
    icon_svg = `<svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 18.5C10.428 18.5 11.7439 18.2694 12.7212 17.8785C13.2085 17.6835 13.6364 17.4389 13.9506 17.1418C14.2659 16.8436 14.5 16.4579 14.5 16C14.5 15.5421 14.2659 15.1564 13.9506 14.8582C13.6364 14.5611 13.2085 14.3165 12.7212 14.1215C11.7439 13.7306 10.428 13.5 9 13.5C7.57196 13.5 6.25609 13.7306 5.27877 14.1215C4.7915 14.3165 4.36361 14.5611 4.04937 14.8582C3.73407 15.1564 3.5 15.5421 3.5 16C3.5 16.4579 3.73407 16.8436 4.04937 17.1418C4.36361 17.4389 4.7915 17.6835 5.27877 17.8785C6.25609 18.2694 7.57196 18.5 9 18.5Z" fill="#212121" stroke="white"/>
        <path d="M15.1251 6.24375C15.1251 3.10161 12.3317 0.625 9.00009 0.625C5.66844 0.625 2.87509 3.10161 2.87509 6.24375C2.87509 8.96453 4.97614 11.1833 7.69652 11.7278V14.9464C7.69652 15.6664 8.28015 16.25 9.00009 16.25C9.72003 16.25 10.3037 15.6664 10.3037 14.9464V11.7278C13.024 11.1833 15.1251 8.96453 15.1251 6.24375Z" fill="#212121" stroke="white"/>
        <circle cx="9" cy="6" r="2" fill="white"/>
        </svg>`;


    get agroStore(): IAgroExportSuperStore{
        return this.store.getRootAs<IAgroExportSuperStore>();
    }
    onSubscription(): IReactionDisposer[] {
        return [
            autorun(() => {
                if (this.agroStore.agroExport.voronovExportStore.showReadyLayers()) {
                    this.showLayers();
                } else {
                    this.hideLayers();
                }
            })
        ];
    }

    get geoJsonLines(): GeoJSON.FeatureCollection<GeoJSON.Geometry>{
        return this.agroStore.agroExport.voronovExportStore.geoJsonLines.data;
    }
    get geoJsonCells(): GeoJSON.FeatureCollection<GeoJSON.Geometry>{
        return this.agroStore.agroExport.voronovExportStore.geoJsonCells.data;
    }
    get geoJsonCellsTracks(): GeoJSON.FeatureCollection<GeoJSON.Geometry>{
        return this.agroStore.agroExport.voronovExportStore.geoJsonCellTracks.data;
    }
    get geoJsonCellsPoints(): GeoJSON.FeatureCollection<GeoJSON.Geometry>{
        return this.agroStore.agroExport.voronovExportStore.geoJsonCellPoints.data;
    }
    get geoJsonSamplePoints(): GeoJSON.FeatureCollection<GeoJSON.Geometry>{
        return this.agroStore.agroExport.voronovExportStore.geoJsonSamplePoints?.data;
    }

    async showLayers(){
        this.hideLayers();
        if (this.map.getSource(SampleSelectionTool.CELLS_NAME) == null) {
            this.map.addSource(SampleSelectionTool.CELLS_NAME, {
                'type': 'geojson',
                'data': this.geoJsonCells
            });
        }
        if (this.map.getSource(SampleSelectionTool.LINES_NAME) == null) {
            this.map.addSource(SampleSelectionTool.LINES_NAME, {
                'type': 'geojson',
                'data': this.geoJsonLines
            });
        }
        if (this.map.getSource(SampleSelectionTool.CELLSTracks_NAME) == null) {
            this.map.addSource(SampleSelectionTool.CELLSTracks_NAME, {
                'type': 'geojson',
                'data': this.geoJsonCellsTracks
            });
        }
        if (this.map.getSource(SampleSelectionTool.CELLS_POINT_NAME) == null) {
            // console.log('addPoints', JSON.parse(JSON.stringify(this.geoJsonCellsPoints || {})));
            this.map.addSource(SampleSelectionTool.CELLS_POINT_NAME, {
                'type': 'geojson',
                'data': this.geoJsonCellsPoints
            });
        }
        if (this.map.getSource(SampleSelectionTool.SAMPLE_POINT_NAME) == null && this.geoJsonSamplePoints) {
            this.map.addSource(SampleSelectionTool.SAMPLE_POINT_NAME, {
                'type': 'geojson',
                'data': this.geoJsonSamplePoints
            });
        }


        if (this.map.getLayer(SampleSelectionTool.CELLS_NAME) == null) {
            this.addLayer({
                'id': SampleSelectionTool.CELLS_NAME,
                'type': 'line',
                'source': SampleSelectionTool.CELLS_NAME,
                'paint': {
                    'line-color': '#000000',
                    'line-opacity': 1.0,
                    'line-width': 2
                },
            });
        }
        if (this.map.getLayer(SampleSelectionTool.LINES_NAME) == null) {
            this.addLayer({
                'id': SampleSelectionTool.LINES_NAME,
                'type': 'line',
                'source': SampleSelectionTool.LINES_NAME,
                'paint': {
                    'line-color': '#be5c00',
                    'line-opacity': 1.0,
                    'line-width': 2,
                    'line-dasharray': [3, 1],
                },
            });
        }
        if (this.map.getLayer(SampleSelectionTool.CELLSTracks_NAME) == null) {
            this.addLayer({
                'id': SampleSelectionTool.CELLSTracks_NAME,
                'type': 'line',
                'source': SampleSelectionTool.CELLSTracks_NAME,
                'paint': {
                    'line-color': '#15db64',
                    'line-opacity': 1.0,
                    'line-width': 4
                },
            });
        }
        if (this.map.getLayer(SampleSelectionTool.CELLS_LABEL_NAME) == null) {
            this.addLayer({
                id: SampleSelectionTool.CELLS_LABEL_NAME,
                source: SampleSelectionTool.CELLS_NAME,
                type: 'symbol',
                minzoom: 13,
                layout: <SymbolLayout>{
                    'text-field': ['get', 'cell_name'],
                    'text-variable-anchor': ['center'],
                    'text-size': 14,
                    //'text-radial-offset': 0.5,
                    //'text-font': ["Open Sans Regular", "Arial Unicode MS Regular"],
                    'text-font': [
                        'Open Sans Semibold'
                    ],
                    visibility: "visible",
                },
                paint: <SymbolPaint>{
                    'text-halo-color': '#C5C5C5',
                    'text-color': "#050607",
                    'text-halo-width': 1,
                    //'text-halo-blur':2,
                }
            });
        }
        if (this.map.getLayer(SampleSelectionTool.CELLS_POINT_NAME) == null) {
            this.addLayer({
                'id': SampleSelectionTool.CELLS_POINT_NAME,
                'type': 'circle',
                'source': SampleSelectionTool.CELLS_POINT_NAME,
                'maxzoom': 15,
                'paint':  <CirclePaint>{
                    'circle-color': '#FFF',
                    'circle-radius': [
                        'interpolate', ['linear'], ['zoom'],
                        12, 3,
                        20, 7
                    ],
                    'circle-stroke-width': 3,
                    'circle-stroke-color': '#15db64'
                },
            });
        }
        if (!this.map.getLayer(SampleSelectionTool.SAMPLE_POINT_NAME) &&
            this.map.getSource(SampleSelectionTool.SAMPLE_POINT_NAME)) {

            let imgName = SampleSelectionTool.SAMPLE_POINT_NAME + '_icon';
            if (!this.map.hasImage(imgName)) {
                await MBUtils.loadSvgToMapbox(this.icon_svg, this.map, 18, 18, imgName);
            }

            // this.addLayer({
            //     'id': SampleSelectionTool.SAMPLE_POINT_NAME,
            //     'type': 'circle',
            //     'source': SampleSelectionTool.SAMPLE_POINT_NAME,
            //     'minzoom': 12,
            //     'paint':  <CirclePaint>{
            //         'circle-color': '#FFF',
            //         'circle-radius': [
            //             'interpolate', ['linear'], ['zoom'],
            //             12, 3,
            //             20, 7
            //         ],
            //         'circle-stroke-width': 3,
            //         'circle-stroke-color': '#15db64'
            //     },
            // });

            this.addLayer({
                'id': SampleSelectionTool.SAMPLE_POINT_NAME,
                'type': 'symbol',
                'source': SampleSelectionTool.SAMPLE_POINT_NAME,
                'minzoom': 15.00001,
                'layout': <SymbolLayout>{
                    'icon-image': imgName,
                    'icon-anchor': 'bottom',
                    "icon-allow-overlap": true,
                },
                'paint':  <SymbolPaint>{
                },
            });
        }


    }
    hideLayers(){
        if (!this.map) return;
        if (this.map.getLayer(SampleSelectionTool.CELLS_LABEL_NAME) != null)
            this.removeLayer(SampleSelectionTool.CELLS_LABEL_NAME);
        if (this.map.getLayer(SampleSelectionTool.LINES_NAME) != null)
            this.removeLayer(SampleSelectionTool.LINES_NAME);
        if (this.map.getLayer(SampleSelectionTool.CELLS_NAME) != null)
            this.removeLayer(SampleSelectionTool.CELLS_NAME);
        if (this.map.getLayer(SampleSelectionTool.CELLSTracks_NAME) != null)
            this.removeLayer(SampleSelectionTool.CELLSTracks_NAME);
        if (this.map.getLayer(SampleSelectionTool.CELLS_POINT_NAME) != null)
            this.removeLayer(SampleSelectionTool.CELLS_POINT_NAME);
        if (this.map.getLayer(SampleSelectionTool.SAMPLE_POINT_NAME) != null)
            this.removeLayer(SampleSelectionTool.SAMPLE_POINT_NAME);

        if (this.map.getSource(SampleSelectionTool.CELLSTracks_NAME) != null)
            this.map.removeSource(SampleSelectionTool.CELLSTracks_NAME);
        if (this.map.getSource(SampleSelectionTool.LINES_NAME) != null)
            this.map.removeSource(SampleSelectionTool.LINES_NAME);
        if (this.map.getSource(SampleSelectionTool.CELLS_NAME) != null)
            this.map.removeSource(SampleSelectionTool.CELLS_NAME);
        if (this.map.getSource(SampleSelectionTool.CELLS_POINT_NAME) != null)
            this.map.removeSource(SampleSelectionTool.CELLS_POINT_NAME);
        if (this.map.getSource(SampleSelectionTool.SAMPLE_POINT_NAME) != null)
            this.map.removeSource(SampleSelectionTool.SAMPLE_POINT_NAME);
    }
}
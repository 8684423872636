// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AgroAhoTableUploadItem-typeItem.AgroExportDropDownComp-title {
  cursor: default;
}

.AgroAhoTableUploadItem-typeItem.AgroExportDropDownComp-title .flex-columns {
  align-items: center;
  flex-grow: 1;
  padding-left: 0.6rem;
}

.AgroAhoTableUploadItem-typeItem.AgroExportDropDownComp-title .select-check {
  display: none;
}

.AgroExportDropDownComp-title .dropDown-text {
  color: #6D7785;
  font-family: Open Sans, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 22px;
}

.AgroAhoTableUploadItem-typeItem.AgroExportDropDownComp-title:hover {
  background: #2B3138;
}

.AgroExportDropDownComp-titleLine {
  height: 0.5rem;
  flex-grow: 1;
  border-top: 1px solid #3E4751;
  margin: 0 0.1rem 0 0.8rem;
}`, "",{"version":3,"sources":["webpack://./plugins/agroExport/components/AgroExportDropDownComp.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AACA;EACE,mBAAA;EACA,YAAA;EACA,oBAAA;AAEF;;AAAA;EACE,aAAA;AAGF;;AADA;EACE,cAAA;EACA,kCAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AAIF;;AAFA;EACE,mBAAA;AAKF;;AAHA;EACE,cAAA;EACA,YAAA;EACA,6BAAA;EACA,yBAAA;AAMF","sourcesContent":[".AgroAhoTableUploadItem-typeItem.AgroExportDropDownComp-title {\r\n  cursor: default;\r\n}\r\n.AgroAhoTableUploadItem-typeItem.AgroExportDropDownComp-title .flex-columns {\r\n  align-items: center;\r\n  flex-grow: 1;\r\n  padding-left: .6rem;\r\n}\r\n.AgroAhoTableUploadItem-typeItem.AgroExportDropDownComp-title .select-check {\r\n  display: none;\r\n}\r\n.AgroExportDropDownComp-title .dropDown-text {\r\n  color: #6D7785;\r\n  font-family: Open Sans, sans-serif;\r\n  font-size: 12px;\r\n  font-weight: 600;\r\n  line-height: 22px;\r\n}\r\n.AgroAhoTableUploadItem-typeItem.AgroExportDropDownComp-title:hover {\r\n  background: #2B3138;\r\n}\r\n.AgroExportDropDownComp-titleLine {\r\n  height: 0.5rem;\r\n  flex-grow: 1;\r\n  border-top: 1px solid #3E4751;\r\n  margin: 0 .1rem 0 .8rem;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

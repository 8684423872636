import {ObservableCustomStore} from "../../app/store/CustomStore";
import {action, observable, reaction} from "mobx";
import {fetchJsonGet} from "../../app/helper/utils/FetchUtils";
import {LinePaint, SymbolLayout, SymbolPaint} from "maplibre-gl";
import {BBox2d} from "@turf/helpers/dist/js/lib/geojson";
import {GeometryUtils} from "../../app/helper/utils/GeometryUtils";
import {AgroAhoStore, AhoStage} from "./agroAhoStore";
import {AhoCardShowType, AhoIndicatorFormat, Card, IndicatorKey} from "./agroAhoCardStore";
import {IReactionDisposer} from "mobx/lib/internal";
import {LeftPanelModeAgroAho} from "./agroAhoPlugin";
import {FeatureCollection} from "geojson";
import centroid from "@turf/centroid";

import {GeoJSON} from "leaflet";
import {Utils} from "../../app/helper/utils/Utils";
import {ra} from "../../app/helper/utils/mobxUtils";
import {LoadStatus} from "../../app/helper/structs/LoadStatus";
import {FieldEditModeType} from "../../app/store/agro/fieldForm/A2FieldFormCustomStore";
import {A2SubModeLeftPanel} from "../../app/store/agro/A2AgroStore";
import {agroAhoVoronovEditManager} from "./map/agroAhoVoronovEditManager";
import {AhoUtils} from "./agroAhoUtils";
import {MeasuringStatus} from "../../app/helper/structs/MeasuringStatus";


export enum AhoEditorMode {
    Disabled='Disabled',
    Select= 'Select',
    Edit = 'Edit',
}

export interface IResponseSampleSelectionData{
    data:  GeoJSON.FeatureCollection<GeoJSON.Geometry>,
    ds_code: string;
}

export class AgroAhoEditorStore extends ObservableCustomStore {
    constructor(parent: AgroAhoStore) {
        super(parent);
        this.parentStore = parent;
    }

    parentStore: AgroAhoStore;

    @observable
    mode: AhoEditorMode = AhoEditorMode.Disabled;
    _lastMode: AhoEditorMode;
    _lastAgroFieldVisible: boolean = true;

    @observable
    cell_id: number;
    @observable
    fieldInfo: any;
    // field_id: number;
    // sum_len_km: number;`1
    cell_bbox: BBox2d;
    editor_geom: string;

    subscription(): IReactionDisposer[]{
        return [
            reaction(()=>this.root.map.measuringStatus,
                ()=>this.doChangeMeasuringStatus())
        ]
    }

    doChangeMeasuringStatus(){
        let map = this.root.map;
        if (map.measuringStatus == MeasuringStatus.Ruler2 || map.measuringStatus == MeasuringStatus.Ruler2Edit ||
            map.measuringStatus == MeasuringStatus.ndviValueByMouse) this.doDisableEditor();
    }

    resetMeasuringStatus(){
        let map = this.root.map;
        if (map.measuringStatus == MeasuringStatus.Ruler2 || map.measuringStatus == MeasuringStatus.Ruler2Edit ||
            map.measuringStatus == MeasuringStatus.ndviValueByMouse)
            map.resetMeasuringStatus();
    }


    // editManager: VoronovEditManager = new VoronovEditManager(this);
    editManager: agroAhoVoronovEditManager = new agroAhoVoronovEditManager(this);

    geoJsonLines: IResponseSampleSelectionData = this.getEmptyDate();
    geoJsonCells: IResponseSampleSelectionData = this.getEmptyDate();
    geoJsonCellTracks: IResponseSampleSelectionData = this.getEmptyDate();

    getEmptyDate(){
        let res: any = {type: "Feature", properties: {name: ''}, geometry: {type: "LineString", coordinates: []}};
        return {data: res, ds_code: ''};
    }

    showReadyLayers(): boolean{
        let store = this.root;
        return store.searchPanel.leftPanelMode == LeftPanelModeAgroAho;
    }

    isEditorActive(){
        let estore = this.parentStore.editorStore;
        return this.root.searchPanel.leftPanelMode == LeftPanelModeAgroAho
            && (estore.mode == AhoEditorMode.Select || estore.mode == AhoEditorMode.Edit);
    }

    resetFieldEditor(){
        // console.log('resetFieldEditor');
        let agro2 = this.parentStore.root.agro2;
        this._lastAgroFieldVisible = agro2.agroFieldVisible;
        // agro2.agroFieldVisible = false;

        this.parentStore.root.map.resetMeasuringStatus();
        this.parentStore.root.agro2.fieldToolController.autoPolygon.editingPolygon = null;
        if (this.parentStore.root.agro2.fieldEditorForm.editMode == FieldEditModeType.multiEdit
            || this.parentStore.root.agro2.fieldEditorForm.editMode == FieldEditModeType.groupEdit
        ){
            this.parentStore.root.agro2.fieldEditorForm.showFieldMultiEditPanel = false;
        }else {
            this.parentStore.root.agro2.subModeLeftPanel = A2SubModeLeftPanel.tree;
        }
    }

    @action
    doEditModeClick(){
        this.mode = AhoEditorMode.Select;
        this.parentStore.mapStore.layerTracksShow = true;
    }

    exit(){
        // this._lastMode = this.mode;
        this.mode = AhoEditorMode.Disabled;
        this.editManager.exit();
    }

    restore(){
        this.mode = this._lastMode;
        this.editManager.restore();
        // this.parentStore.mapStore.trackOpacity = this.mode == AhoEditorMode.Edit;
        // this.parentStore.mapStore.setTrackOpacity(this.mode == AhoEditorMode.Edit).then();
    }

    @action
    doDisableModeClick(){
        this.mode = AhoEditorMode.Disabled;
        this.editManager.exit();
    }

    @action
    doSelectModeClick(){
        let mstore = this.parentStore.mapStore;
        this.mode = AhoEditorMode.Select;
        this.editManager.exit();
        mstore.setTrackOpacity(false);
        mstore.trackFieldPointStore.setTFPHidden(false);
        mstore.mapSampleStore.setSLHidden(false);
    }

    getEditTrackFieldId(){
        let fid: any = null;
        if (this.parentStore.root.searchPanel.leftPanelMode == LeftPanelModeAgroAho &&
            this.parentStore.editorStore.mode == AhoEditorMode.Edit) {
            this.parentStore.cardStore.cellsItems.forEach(c=>{
                if (c.cell_id == this.cell_id) fid = c.field_id;
            });
        }
        return fid;
    }

    doDisableEditor(){
        let mstore = this.parentStore.mapStore;
        if (this.mode == AhoEditorMode.Select || this.mode == AhoEditorMode.Edit) {
            if (this.mode == AhoEditorMode.Edit) {
                mstore.setTrackOpacity(false);
                mstore.trackFieldPointStore.setTFPHidden(false);
                mstore.mapSampleStore.setSLHidden(false);
            }
            this.mode = AhoEditorMode.Disabled;
            this.editManager.exit();
        }
    }

    @action
    async doSaveClick(){
        let r: any = await this.saveGeom();
        if (r?.status == 'ok') {
            this.mode = AhoEditorMode.Select;
            this.editManager.exit();
            await this.parentStore.mapStore.setTrackOpacity(false);
            await this.parentStore.mapStore.updTrackLayers();
            await this.parentStore.mapStore.trackFieldPointStore.setTFPHidden(false);
            await this.parentStore.mapStore.mapSampleStore.setSLHidden(false);
            await this.parentStore.mapStore.trackFieldPointStore.updTFPLayers();
            await this.parentStore.mapStore.mapSampleStore.updSampleLayer();
        } else {
            let err = 'error save track';
            if (r?.status == 'error') err = r?.msg;
            this.root.addError(Utils.getErrorString(err));
        }
    }

    @action
    doZoomClick(){
        // this.root.map.zoomToBBox(this.cell_bbox);
        let field_id = this.getEditTrackFieldId();
        if (!field_id) return;
        let field: any = {field_id: field_id};
        this.parentStore.cardStore.doFieldZoomClick(field, 0.005);
    }

    async saveGeom(){
        let sg = this.editManager.viewTool.state.simpleGeometry;
        let r = [
            this.parentStore.projStore.projName,
            this.parentStore.cardStore.card.map_id,
            this.cell_id,
            JSON.stringify(sg[0].contour)
        ]
        let url = `${window.location.origin}/api/projects/${r[0]}/asa2/map/${r[1]}/track/modify?cell_id=${r[2]}&cell_track=${r[3]}`;
        // let url = `${window.location.origin}/api/projects/${r[0]}/asa_map/${r[1]}/track/modify?cell_id=${r[2]}&cell_track=${r[3]}`;
        try {
            return await fetchJsonGet(url);
        }catch (e) {
            this.root.addError(e);
        }
    }


}
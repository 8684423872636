import {Translate} from "../../../app/helper/lang/Translate";

export class AgroExportTranslate_en extends Translate{
    "Save new ASA maps"=""
    "Save to existing ASA maps"=""
    "ASA map name"=""
    "Search by ASA map"=""
    "Cell names"=""
    "Can not add"=""
    "The following fields are already in the ASA map"=""
    "It is necessary to remove intersecting fields from the ASA map or from the current breakdown"=""
    "Unable to save"=""
    "The following cell names are already in the ASA map"=""
    'It is necessary to change the names in the breakdown, for this use the setting "Cell names with"'=""
    "Number of fields"=""
    "Add pricks:"=""
    "Visible on the map with zoom 16"=""
    "Data saved in ASA map"=""
    "Save error"=""
    'ASA map with name "{0}" already exists'=""
    "By average cell area"=""
    "By total number of cells"=""
    "By the number of cells for each field"=""
    "Construction method"=""
    "Avg. square"=""
    "Cell names with"=""
    "Pricks per cell"=""
    "Numbers"=""
    "Names"=""
    "Cells"=""
    "pcs"=""
    "Voronoi diagrams / avg. area"=""
    "Voronoi diagrams / number of samples"=""
    "Rectangular grid / number of samples"=""
    "Rectangular grid / avg. area"=""
    "Voronoi diagrams"=""
    "Adaptive partitioning"=""
    "Rectangular grid"=""
    "Z-shaped"=""
    "Linear"=""
    "Selection route"=""
    "Num. of sampl."=""
    "Diagonal"=""
    "Avg. line"=""
    "Preparations for export are underway. It won't take long"=""
}

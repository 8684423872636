import {CustomStore} from "../../../app/store/CustomStore";
import {observable} from "mobx";
import {VoronovExportStore} from "./VoronovExportStore";
import {fetchJsonGet, fetchJsonPost} from "../../../app/helper/utils/FetchUtils";
import {IAgroExportSuperStore} from "../agroExportPlugin";
import {TextUtils} from "../../../app/helper/utils/TextUtils";
import {TranslateUtils} from "../../../app/helper/lang/TranslateUtils";


export interface CellInfo {
    cell_cnt: number;
    ds_code: string;
}

export interface AsaMap {
    map_id: number;
    map_name: string;
    cell_info: CellInfo;
    cell_names?: string;
}

export enum ExportType {
    NotSet = 'NotSet',
    New = 'New',
    Exist = 'Exist'
}

export enum MsgType {
    NoAdd = 'NoAdd',
    NoSave = 'NoSave'
}


export class VoronovSaveStore extends CustomStore<VoronovExportStore>{
    @observable
    showSaveForm: boolean = false;
    @observable
    saveTitle: string = '';
    @observable
    numberFrom: string = '';
    @observable
    paths: string[] = [];
    @observable
    fieldsCount: number = 0;
    @observable
    exportType: ExportType = ExportType.New;
    @observable
    asaMapId: number = null;
    @observable
    asaMaps: AsaMap[];
    @observable
    cellNames: string = '';
    @observable
    showMsgForm: boolean = false;
    @observable
    msgType: MsgType = MsgType.NoAdd;
    msgData: string = '';

    async reset(){
        let store_ = this.root.getRootAs<IAgroExportSuperStore>().agroExport;
        this.saveTitle = '';
        this.exportType = ExportType.New;
        await this.loadAsaMaps();
        this.asaMapId = null;
        this.numberFrom = '1';
    }

    async loadAsaMaps(){
        let proj_name = this.root.agro.projectName;
        let url = `/api/projects/${proj_name}/asa2/map/select`;
        let asaMaps = await fetchJsonGet(url).catch(()=>{});
        if (!asaMaps) return;
        this.asaMaps = asaMaps.sort((a: any,b: any)=> TextUtils.stringSortCompare(a.map_name, b.map_name));
    }

    updCellNames(){
        // console.log('point1', this.asaMaps?.length);
        if (this.asaMaps?.length) {
            this.asaMaps.forEach(async m=>{
                let ds = m.cell_info?.ds_code;
                if (ds) {
                    let stat: any = {min: null, max: null};
                    let url = `${window.location.origin}/api/vectorlayer/${ds}/geojson?geom=0`;
                    let am = await fetchJsonGet(url).catch(()=>{});
                    // let noNum = false;
                    // console.log('point2', am?.features?.length, JSON.parse(JSON.stringify(am || {})));
                    if (am?.features?.length) {
                        am.features.forEach((f: any)=>{
                            // if (!noNum) {
                                let name = f?.properties?.cell_name;
                                if (/^[1-9]+[0-9]*$/.test(name)) {
                                //     noNum = true;
                                //     stat = {min: null, max: null};
                                // } else {
                                    if (stat.min > +name || stat.min === null) stat.min = +name;
                                    if (stat.max < +name || stat.max === null) stat.max = +name;
                                }
                            // }
                        });
                    }
                    if (stat.min === null || stat.max === null) m.cell_names = ''
                    else m.cell_names = `${stat.min} - ${stat.max}`;
                }
            });
        }
    }

    async sendSave(){
        let store_ = this.root.getRootAs<IAgroExportSuperStore>().agroExport;
        if (this.exportType == ExportType.New) {
            let url = `/api/projects/${this.root.agro.projectName}/asa_map/create`;
            // let field_ids = this.root.agro2.projectStruct.activeNavigatorPage.selectedFields.map(a => a.id);

            // /api/projects/kazm11/asa_map/create?
            // mode=probe_plan&map_name=Test-501&
            // field_ds_code=tmp_app_asa_tmp_field_709242207&
            // cell_ds_code=tmp_app_asa_tmp_cell_209154092&
            // track_ds_code=tmp_app_asa_tmp_track_253086022&
            // sample_point_ds_code=tmp_app_asa_tmp_sample_point_414339509

            let params: any = {
                mode:"probe_plan",
                map_name: this.saveTitle,
                field_ds_code: this.parent.field_ds_code,
                //field_filters:{"field_id":{"$in": field_ids}},//<-
                cell_ds_code:this.parent.geoJsonCells.ds_code,
                track_ds_code:this.parent.geoJsonCellTracks.ds_code,
                // sample_point_ds_code: this.parent.geoJsonSamplePoints.ds_code,
                start_cell_name: this.numberFrom
            };
            let sds = this.parent.geoJsonSamplePoints?.ds_code;
            if (sds) params.sample_point_ds_code = sds;
            fetchJsonPost(url, params).then(r=>{
                if (!!r?.map_id) {
                    this.root.addInfo(`${store_.trans['Data saved in ASA map']}: «${this.saveTitle}»`)
                } else this.root.addError(r?.message || store_.trans['Save error']);
            }).catch(err => {
                let key_double_msg = 'duplicate key value violates unique constraint "uk_map_name"';
                if (err && err.indexOf(key_double_msg) >= 0) {
                    this.root.addError(TranslateUtils.format(
                        store_.trans['ASA map with name "{0}" already exists'],
                        this.saveTitle));
                } else this.root.addError(err);
            });
        }
        if (this.exportType == ExportType.Exist) {
            // /api/projects/proj_name/asa2/map/map_id/add
            if (!this.asaMapId) return;
            let url = `/api/projects/${this.root.agro.projectName}/asa2/map/${this.asaMapId}/add`;
            // let field_ids = this.root.agro2.projectStruct.activeNavigatorPage.selectedFields.map(a => a.id);
            let params: any = {
                // mode:"probe_plan",
                // map_name: this.saveTitle,
                field_ds_code: this.parent.field_ds_code,
                //field_filters:{"field_id":{"$in": field_ids}},//<-
                cell_ds_code:this.parent.geoJsonCells.ds_code,
                track_ds_code:this.parent.geoJsonCellTracks.ds_code,
                // sample_point_ds_code: this.parent.geoJsonSamplePoints.ds_code,
                start_cell_name: this.numberFrom
            };
            let sds = this.parent.geoJsonSamplePoints?.ds_code;
            if (sds) params.sample_point_ds_code = sds;
            fetchJsonPost(url, params).then(r=>{
                if (r?.status == 'error'  && r?.error_code == 'dupl_fields' && r?.dupl_fields?.length) {
                    this.msgData = r.dupl_fields.map((v: any)=>v.field_name).join(', ');
                    this.msgType = MsgType.NoAdd;
                    this.showMsgForm = true;
                } else if (r?.status == 'error' && r?.error_code == 'dupl_cells' && r?.dupl_cells?.length) {
                    let names = r.dupl_cells.map((c: any)=>c.cell_name);
                    // console.log('names:', JSON.parse(JSON.stringify(names.map((n: any)=>{return {n: n, c: /^[1-9]+[0-9]*$/.test(n+'')}}))));
                    if (names.every((n: any)=>/^[1-9]+[0-9]*$/.test(n+'')))
                        names = names.map((n: any)=>+n).sort((a: any, b: any) => a - b);
                    // this.msgData = r.dupl_cells.map((c: any)=>c.cell_name).join(', ');
                    this.msgData = names.join(', ');
                    this.msgType = MsgType.NoSave;
                    this.showMsgForm = true;
                } else if (r?.status == 'ok') {
                    let map_name = this.saveTitle;
                    if (this.exportType == ExportType.Exist) {
                        let maps = this.asaMaps.filter(m=>m.map_id == this.asaMapId);
                        if (maps?.length) map_name = maps[0].map_name;
                    }
                    this.root.addInfo(`${store_.trans['Data saved in ASA map']}: «${map_name}»`)
                } else this.root.addError(r?.message || store_.trans['Save error']);
            }).catch(err => this.root.addError(err));
        }
    }
}
import {ObservableCustomStore} from "../../../app/store/CustomStore";
import {action, autorun, computed, observable} from "mobx";
import {IReactionDisposer} from "mobx/lib/internal";
import {ra} from "../../../app/helper/utils/mobxUtils";
import {Utils} from "../../../app/helper/utils/Utils";
import {fetchJsonGet, fetchJsonPost} from "../../../app/helper/utils/FetchUtils";
import {isEqual} from "lodash-es";
import {save} from "../../../app/store/PermalinkDecor";
import {A2ExportFormat} from "../../../app/store/agro/A2AgroStore";
import {GeoJSON} from "leaflet";
import {LoadStatus} from "../../../app/helper/structs/LoadStatus";
import {VoronovSaveStore} from "./VoronovSaveStore";
import {VoronovEditManager} from "../tools/VoronovEditManager";
import {LeftPanelMode} from "../../../app/store/SearchStore";
import {IAgroExportSuperStore} from "../agroExportPlugin";
import {AgroAhoStore} from "../../agroAho/agroAhoStore";
import {AgroExportStore} from "./agroExportStore";
import {Feature} from "geojson";

export enum SampleSelectionUrl{
    download = "download",
    geojson = "geojson"
}
interface ISampleSelectionParams {
    // numberFrom: number;
    is_pricks: boolean;
    point_cnt: number;
    cellArea: number;
    selRoute: string;
    url: string;
}
export interface IResponseSampleSelectionData{
    data:  GeoJSON.FeatureCollection<GeoJSON.Geometry>,
    ds_code: string;
}
interface IResponseSampleSelectionGetAsJson{
    field: {"ds_code": string},
    cell: IResponseSampleSelectionData,
    field_track: IResponseSampleSelectionData,
    cell_track: IResponseSampleSelectionData,
    sample_point: IResponseSampleSelectionData,
}
export enum ConstrMethod {
    ByVorAvgArea = 'ByVorAvgArea',
    ByVorNumSamples = 'ByVorNumSamples',
    ByRectNumSamples = 'ByRectNumSamples',
    ByRectAvgArea = 'ByRectAvgArea'
}
export enum SelectionRoute {
    Diagonal = 'diagonal',
    AvgLine = 'middle_segment',
    ZShaped = 'z'
}

export class VoronovExportStore extends ObservableCustomStore{
    @save @observable
    private _showVoronovWidget: boolean = false;
    get showVoronovWidget(): boolean{
        return this._showVoronovWidget;
    }
    @save @observable
    constrMethod: ConstrMethod = ConstrMethod.ByRectAvgArea;
    @save @observable
    selectionRoute: SelectionRoute = SelectionRoute.Diagonal;
    geoJsonLines: IResponseSampleSelectionData;//GeoJSON.FeatureCollection<GeoJSON.Geometry>;
    geoJsonCells: IResponseSampleSelectionData;
    geoJsonCellTracks: IResponseSampleSelectionData;
    geoJsonCellPoints: IResponseSampleSelectionData;
    geoJsonSamplePoints: IResponseSampleSelectionData;
    field_ds_code: string; // код по которому можно будет потом скачать
    cell_ds_code: string;
    track_ds_code: string;
    sample_point_ds_code: string;
    @observable
    statusLoad: LoadStatus = null;

    editManager: VoronovEditManager = new VoronovEditManager(this);

    @save @observable
    pathFields: string = "";

    saveOptions = new VoronovSaveStore(this);

    @save @observable
    cellArea: string = "5";

    // @save @observable
    // numberFrom: string = "1";
    @save @observable
    pricks: string = "10";
    @save @observable
    isPricks: boolean = false;

    @observable
    curParams: ISampleSelectionParams = null;
    @observable
    showLayers: boolean = true;
    @observable
    numberOfSaves: number = 0;  // анимация иконки "анализ почвы"


    showReadyLayers(): boolean{
        let store = this.root.getRootAs<IAgroExportSuperStore>();
        return store.agroExport.voronovExportStore.showLayers && store.searchPanel.leftPanelMode == LeftPanelMode.agro
            && store.agroExport.voronovExportStore.statusLoad == LoadStatus.ready;
    }

    canEdit(): boolean{
        let store = this.root.getRootAs<IAgroExportSuperStore>();
        return !store.agroExport.voronovExportStore.showVoronovWidget;
    }

    @computed
    get needApply(): boolean{
        let p = this.getParams();
        return (!isEqual(p, this.curParams));
    }

    @action
    resetJson(){
        this.geoJsonCellTracks = null;
        this.geoJsonCellPoints = null;
        this.geoJsonCells = null;
        this.field_ds_code = null;
        this.geoJsonLines = null;
        this.statusLoad = null;
    }

    getJsonCellPoints():IResponseSampleSelectionData{
        let fdata = this.geoJsonLines?.data?.features;
        // console.log('getJsP', JSON.parse(JSON.stringify((this.geoJsonLines?.ds_code || {}))));
        if (!fdata) return;
        let feats: Feature[] = [];
        fdata.forEach((f: any, idx: number)=>{
            let coords = f.geometry.coordinates;
            feats.push({"type": "Feature", "properties": {}, "geometry": {"type": "MultiPoint",
                    "coordinates": [coords[0], coords[coords.length - 1] ]}});
        });
        return {ds_code: this.geoJsonLines?.ds_code, data: {'type': 'FeatureCollection', 'features': feats}};
    }

    @action
    async apply(){
        try {
            this.checkParams();
            let p = this.getParams();
            this.resetJson();
            this.statusLoad = LoadStatus.loading;
            this.curParams = p;
            // let r = await fetchJsonGet<IResponseSampleSelectionGetAsJson>(p.url);
            // todo get to post /?link=UIvvkwBo
            // console.log('url', p.url);
            let r = p.url.indexOf('field_ds_code=agro_base_kazm11_v_field') > 0 ? await fetchJsonPost(p.url) :
                await fetchJsonGet<IResponseSampleSelectionGetAsJson>(p.url);
            ra(()=>{
                let p2 = this.getParams();
                if (isEqual(p, p2) && this.showVoronovWidget){
                    this.geoJsonLines = r.field_track;
                    this.geoJsonCells = r.cell;
                    this.geoJsonCellTracks = r.cell_track;
                    this.geoJsonCellPoints = this.getJsonCellPoints();
                    // console.log('geoJs', JSON.parse(JSON.stringify((this.geoJsonCellPoints))));
                    this.geoJsonSamplePoints = r.sample_point;
                    this.field_ds_code = r.field.ds_code;
                    this.cell_ds_code = r.cell.ds_code;
                    this.track_ds_code = r.cell_track.ds_code;
                    if (r.sample_point?.ds_code) this.sample_point_ds_code = r.sample_point.ds_code;
                    this.statusLoad = LoadStatus.ready;
                    //this.root.map.superTools.sampleSelection.showLayers();
                }
            });
        }catch (err){
            this.statusLoad = null;
            this.curParams = null;
            throw err;
        }
    }

    getParams(): ISampleSelectionParams{
        let cellArea = Utils.parseInt(this.cellArea);
        // let numberFrom = Utils.parseInt(this.numberFrom);
        let pricks = Utils.parseInt(this.pricks);
        let r: ISampleSelectionParams = {
            // numberFrom: numberFrom,
            is_pricks: this.isPricks,
            point_cnt: pricks,
            cellArea: cellArea,
            selRoute: this.selectionRoute,
            url: this.getUrl(SampleSelectionUrl.geojson)
        };
        return r;
    }

    checkParams(): void{
        let cellArea = Utils.parseNumber(this.cellArea);
        if (!cellArea) throw "Invalid cell area";
        // let numberFrom = Utils.parseInt(this.numberFrom);
        // if (numberFrom == null) throw "Invalid number from";
    }

    setExportPanel(){
        this.pricks = '10';
        this._showVoronovWidget = true;
        this.pathFields = this.getAnchorFieldsPath();
    }

    @action
    hideWidget(){
        this._showVoronovWidget = false;
        this.curParams = null;
        this.resetJson();
    }

    getAnchorFieldsPath(): string{
        let s = this.root.agro.projectName;
        if (this.root.agro2.projectStruct.activeNavigatorPage != null)
            s += this.root.agro2.projectStruct.activeNavigatorPage.getNaviPath().map(a => a.column_stringValue).join("-");
        return s;
    }

    subscription(): IReactionDisposer[] {
        //return [];
        // автозакрытие виджетаы
        return [autorun(()=>{
            let newPathFields = this.getAnchorFieldsPath();
            if (this.pathFields == null){
                ra(()=>{this.pathFields = newPathFields;});
            }else{
                if (this.pathFields != newPathFields){
                    this.hideWidget();
                }
            }

        })];
        /*
        return [autorun(()=>{
            if (this.showVoronovWidget){

                if (this.root.agro2.projectStruct.fieldsPage == null ||
                    this.root.agro2.projectStruct.fieldsPage.selectedFields.length == 0) {
                        this.hideWidget();
                }
            }
        })];*/
    }

    getUrl(type: SampleSelectionUrl, format: A2ExportFormat = A2ExportFormat.geojson): string{
        let filter: any ={};
        if (this.root.agro2.projectStruct.fieldsPage == null) return "";
        let field_ids = this.root.agro2.projectStruct.fieldsPage.selectedFields.map(a => a.id);
        if (field_ids.length == 0) return "";
        filter[this.root.agro2.projectInfo.fieldName_id] = {"$in":field_ids};

        let dataset = this.root.agro2.projectInfo.projectInfoJson.type_info.type_schema.obj.view.dataset.ds_code;
        let numType = this.constrMethod == ConstrMethod.ByVorNumSamples ||
            this.constrMethod == ConstrMethod.ByRectNumSamples ? 'cell_count' : 'cell_ha';
        let cell_type = this.constrMethod == ConstrMethod.ByVorAvgArea ? 'auto' : 'rect';

        // let url = `/api/agrm/split?cell_ha=${this.cellArea}&start_cell_id=${this.numberFrom}&filter=${JSON.stringify(filter)}`;
        // let url = `/api/agrm/split?method=voronoi_area&track_type=${this.selectionRoute}&${numType}=${this.cellArea}&start_cell_id=${this.numberFrom}&filter=${JSON.stringify(filter)}`;
        // let url = `/api/agrm/split?cell_type=${cell_type}&track_type=${this.selectionRoute}&${numType}=${this.cellArea}&start_cell_id=${this.numberFrom}&filter=${JSON.stringify(filter)}`;
        let url = `/api/agrm/split?cell_type=${cell_type}&track_type=${this.selectionRoute}&${numType}=${this.cellArea}&filter=${JSON.stringify(filter)}`;
        if (this.isPricks) url += `&point_cnt=${this.pricks}`
        if (type == SampleSelectionUrl.download) {
            url += `&mode=export&format=${format}&sample_point_ds_code=${encodeURIComponent(this.sample_point_ds_code)}&field_ds_code=${encodeURIComponent(this.field_ds_code)}&cell_ds_code=${encodeURIComponent(this.cell_ds_code)}&track_ds_code=${encodeURIComponent(this.track_ds_code)}&proj_name=${this.root.agro.projectName}`;
        }
        else
        if (type == SampleSelectionUrl.geojson)
            url += `&mode=json&field_ds_code=${dataset}`;
        else throw "Unknown type url";
        return url;
    }

    getStat(){
        let cells = '';
        let numbers = '';
        let st = {cells: 0, min: 0, max: 0};
        if (this.geoJsonCells?.data?.features) {
            this.geoJsonCells.data.features.forEach(f=>{
                st.cells += 1;
                let cnum = +f.properties.cell_id;
                if (st.min > cnum || !st.min) st.min = cnum;
                if (st.max < cnum || !st.max) st.max = cnum;
            });
            cells = st.cells+'';
            numbers = `${st.min} - ${st.max}`;
        }
        return {cells: cells, numbers: numbers};
    }
}
import * as React from 'react';
import {ReactNode, RefObject} from 'react';
import {observer} from "mobx-react";
import {action, observable} from "mobx";
import {isBoolean, isFunction} from "lodash-es";
import autoBindReact from "auto-bind/react";
import classNames from "classnames";
import './AgroExportDropDownComp.scss'
import {Utils} from "../../../app/helper/utils/Utils";
import {TextUtils} from "../../../app/helper/utils/TextUtils";
import {ContextMenuCommonComp} from "../../../app/components/Common/ContextMenuCommonComp";


export interface IAgroAhoDropDownItem{
    key: string | number;
    value: string;
    childNode?: ReactNode;
    data?: any;
    class?: string;
    title?: boolean;
}

export interface IAgroExportDropDownCompProps {
    items: IAgroAhoDropDownItem[];
    currentKey: string | number;
    placeHolder?: string;
    placeHolderSearch?: string;
    disabled?: boolean;
    noSearch?: boolean;
    className?: string;
    classNameDown?: string;
    closeOnItemClick?: boolean;//default: true
    onChange?:(item: IAgroAhoDropDownItem, newIndex: number)=>void;
    emptyText?: string;
    emptyClass?: string;
    caption?: HTMLDivElement;
}

@observer
export class AgroExportDropDownComp extends React.PureComponent<IAgroExportDropDownCompProps, undefined> {
    constructor(props: IAgroExportDropDownCompProps) {
        super(props);
        autoBindReact(this);
        this.refList = React.createRef();
    }

    @observable
    private down: boolean = false;

    disabled(): boolean{
        return !!this.props.disabled;
    }

    @action
    onClick(){
        if (this.disabled()) return ;
        this.down = !this.down;
        //setTimeout(()=>{this.doScroll()}, 300);
    }
/*
    doScroll(){
        if (this.refItem.current != null){
            //console.log(this.refItem.current);
            let target = this.refItem.current;
            target.parentNode.scrollTop = target.offsetTop;
            //Эта фигня в каких то случаях прокручивает всю страницу. Неюзабольна.
            //this.refItem.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        }
    }*/

    onClickItem(e: React.MouseEvent<HTMLDivElement>){
        if (this.disabled()) return ;
        let key = e.currentTarget.getAttribute("data-key");
        this.setCurrentItem(key);
        //console.log(key);
    }
    onClickTitle(e: any){
        e.preventDefault();
        e.stopPropagation();
        return false;
    }
    setCurrentItem(key: any){
        let index = this.props.items.findIndex(a => a.key == key);
        let v:IAgroAhoDropDownItem = null;
        if (index >= 0) v = this.props.items[index];
        if (isFunction(this.props.onChange)){
            this.props.onChange(v, index);
        }
        if (!isBoolean(this.props.closeOnItemClick) || this.props.closeOnItemClick){
            this.down = false;
        }
    }


    onChangeSelect(e: React.ChangeEvent<HTMLSelectElement>){

    }

    @action
    onClosePopup(){
        this.searchText = "";
        this.down = false;
    }
    refList: RefObject<any>;
    refItem: RefObject<any>;
    @observable
    searchText: string = "";
    @observable
    highlightLineKey: any = null;


    onChangeSearch(e: any){
        let txt = e.currentTarget.value;
        this.searchText = txt;
    }
    @action
    onKeyUp(e: any) {
        if  (e.keyCode == 27) {//esc
            e.stopPropagation();
            e.preventDefault();
            this.onClosePopup();
        }
    }

    getFilteredItems(): IAgroAhoDropDownItem[]{
        let arr = this.props.items;
        let upperSearchText = this.searchText.toUpperCase();
        function isFilterText(v: string): boolean{
            if (upperSearchText == "") return true;
            if (v == null) return false;
            if (v.toUpperCase().includes(upperSearchText)){
                return true;
            }else return false;
        }
        return arr.filter((a, index) => {
            let txt = a.value;

            return isFilterText(txt);
        });
    }


    @action
    onKeyDown(e: any){
        let items = this.getFilteredItems();
        if (e.keyCode == 13){
            if (!!this.props.disabled) return;
            let t = items.find(a => a.key == this.highlightLineKey);
            if (t != null){
                this.setCurrentItem(t.key);
                this.highlightLineKey = null;
                return;
            }
            e.stopPropagation();
            e.preventDefault();
        }else
        if (e.keyCode == 9){//tab - оставляем стандартную обработку
            this.down = false;
            this.highlightLineKey = null;
        }else
        if (e.keyCode == 27){//esc
            this.down = false;
            this.highlightLineKey = null;
            e.stopPropagation();
            e.preventDefault();
        }else
        if (e.keyCode == 40) {//вниз
            if (!!this.props.disabled) return;
            if (items.length > 0) {
                if (this.highlightLineKey == null) this.highlightLineKey = items[0].key;
                else {
                    let idx = items.findIndex(a => a.key == this.highlightLineKey);
                    if (idx >= 0) {
                        idx++;
                        if (idx >= 0 && idx < items.length) {
                            this.highlightLineKey = items[idx].key;
                        }
                    }else this.highlightLineKey = items[0].key;
                }
            }
            this.down = true;
            e.stopPropagation();
            e.preventDefault();
        }else
        if (e.keyCode == 38) {//вверх
            if (!!this.props.disabled) return;
            if (items.length > 0) {
                if (this.highlightLineKey == null) this.highlightLineKey = items[0].key;
                else {
                    let idx = items.findIndex(a => a.key == this.highlightLineKey);
                    if (idx >= 0) {
                        idx--;
                        if (idx >= 0 && idx < items.length) {
                            this.highlightLineKey = items[idx].key;
                        }
                    }else this.highlightLineKey = items[0].key;
                }
            }
            this.down = true;
            e.stopPropagation();
            e.preventDefault();
        }else{
            this.highlightLineKey = null;
        }
    }
    scrollToTimer(){
        if (this.refItem.current != null){
            if (!checkInView(this.refItem.current.parentNode, this.refItem.current, false))
                Utils.scrollIntoView2(this.refItem.current);
        }
        function checkInView(container:any, element:any, partial:any):boolean {

            //Get container properties
            let cTop = container.scrollTop;
            let cBottom = cTop + container.clientHeight;

            //Get element properties
            let eTop = element.offsetTop;
            let eBottom = eTop + element.clientHeight;

            //Check if in view
            let isTotal = (eTop >= cTop && eBottom <= cBottom);
            let isPartial = partial && (
                (eTop < cTop && eBottom > cTop) ||
                (eBottom > cBottom && eTop < cBottom)
            );

            //Return outcome
            return  (isTotal  || isPartial);
        }
    }


    render() {
        let optArr: any[] = [];
        let divArr: any[] = [];


        let arr = this.getFilteredItems();
        let ref: any = null;

        arr.forEach((a, index) =>{
            optArr.push(<option value={a.key} key={a.key}>{a.value}</option>);
            let key = a.key;
            let localRef = null;
            if (key == this.highlightLineKey) {
                ref = React.createRef();
                localRef = ref;
            }else if (ref == null && key == this.props.currentKey && key != this.highlightLineKey){
                ref = React.createRef();
                localRef = ref;
            }

            let v: any = a.childNode;
            let txt = a.value;

            let class_ = "flex-columns ";
            if (this.props.emptyClass) class_ += this.props.emptyClass;

            if (!v){
                let spanWords: any[] = [];
                let words = TextUtils.findSubstrings(txt,this.searchText, false);
                if (words.length == 1 && words[0] == '' && this.props.emptyText) {
                    v = <div className={class_}>
                        <span key='0'>{this.props.emptyText}</span>
                    </div>
                } else {

                    words.forEach((w, index)=>{
                        let eq = TextUtils.isEqual(w, this.searchText, false);
                        spanWords.push(<span key={index.toString()} className={classNames({
                            "dropDown-text-highlight": eq,
                            "dropDown-text": !eq
                        })}>{w}</span>);
                    });
                    // console.log('spanWords', words, spanWords, this.props.emptyText);
                    // if (!spanWords.length && this.props.emptyText) spanWords.push(<span>{this.props.emptyText}</span>)

                    v = <div className="flex-columns">
                        <div className={classNames("select-check",{"hide": a.key != this.props.currentKey})}>
                            <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.2 6.19995L1.8 3.79995L0 5.59995L4.2 9.79995L12 1.99995L10.2 0.199951L4.2 6.19995Z" fill="#4DB6BC"/>
                            </svg>
                        </div>
                        <div>{spanWords}</div>
                        {!!a.title && <div className="AgroExportDropDownComp-titleLine" />}
                    </div>;

                }
            } else {
                v = <div className="flex-columns">
                    <div className={classNames("select-check",{"hide": a.key != this.props.currentKey})}>
                        <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.2 6.19995L1.8 3.79995L0 5.59995L4.2 9.79995L12 1.99995L10.2 0.199951L4.2 6.19995Z" fill="#4DB6BC"/>
                        </svg>
                    </div>
                    <div className="AgroExportDropDownComp-childNode">{v}</div>
                </div>;
            }
            divArr.push(<div key={a.key}
                             ref={localRef} data-val={a.value}
                             className={classNames(a.class, "dropDown-line", {
                                 "dropDown-lineHighlight": this.highlightLineKey != null && this.highlightLineKey == a.key,
                                 "notSelected": a.key != this.props.currentKey,
                                 "same-as-selected": a.key == this.props.currentKey,
                                 "disable-text": this.disabled(),
                                 "AgroExportDropDownComp-title": a.title})}
                             data-key={a.key} onClick={a.title ? this.onClickTitle : this.onClickItem}>{v}</div>);
        });
        if (ref != null){
            this.refItem = ref;
            setTimeout(()=> {this.scrollToTimer();}, 100);
        }

        let text = "";
        let selData: any = null;
        let curKey: string | number = "";
        let curItem = this.props.items.find(a => a.key == this.props.currentKey);
        if (curItem != null){
            text = curItem.value;
            selData = curItem.childNode;
            curKey = curItem.key;
        } else{
            text = this.props.placeHolder??"";
        }
        if (!selData) selData = <span>{text}</span>;

        let stPopup: any = {};
        if (this.refList.current != null){
            stPopup["minWidth"] = this.refList.current.offsetWidth + "px";
        }

        return <div className={classNames("custom__select", this.props.className)}
                    tabIndex={0} ref={this.refList}
                    onClick={this.onClick}>
            <select onChange={this.onChangeSelect} value={curKey}>
                <option value=""/>
                {optArr}
            </select>
            {/*<div className={classNames("select-selected", {"disable-text disable-backgroundColor-after": this.disabled()} )}><span>{text}</span></div>*/}
            <div className={classNames("select-selected", {"disable-text disable-backgroundColor-after": this.disabled()} )}>{this.props.caption}{selData}</div>
            {this.down && <ContextMenuCommonComp className={classNames("dropDown-downlist", this.props.classNameDown)}  parentClickNotIgnore={true}
                                                 style={stPopup} indentVertical={5}
                                                 onClose={this.onClosePopup}>
                {(!this.props.noSearch && (divArr.length > 6 || this.searchText != "")) && <div className="dropDown-searchDiv">
                    <input type="text" readOnly={!!this.props.disabled} onKeyDown={this.onKeyDown}
                           className={classNames("DropText-input")} onKeyUp={this.onKeyUp} autoFocus={true}
                           value={this.searchText??""}
                           onChange={this.onChangeSearch}
                           placeholder={this.props.placeHolderSearch??""} />
                </div>}
                <div className={classNames("select-items style-4 dropDown-items")}>
                    {divArr}
                </div>
            </ContextMenuCommonComp>}
        </div>;
    }
}
